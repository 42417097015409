import { useState } from "react";
import { EMAIL, MOBILE, OPEN_TIMINGS } from "../../Constants/common";
import { submitContactForm } from "../../Services/contact-us";
import Locations from "../Locations/locations";

function ContactUs() {

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name, value);
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let payload = {
                ...inputs, ...{
                    "mode": "TELEGRAM",
                    "partner": "KAMAL_STEEL"
                }
            }
            await submitContactForm(payload);
            alert('We will get back in touch with you soon.');
            window.location.reload();
        } catch (error) {
            alert(error);
        }
    }

    return (
        <div className="contact">
            <div className="container">
                <div className="section-header text-center">
                    <p>Get In Touch</p>
                    <h2>Contact for any query</h2>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="contact-info">
                            <h2>Quick Contact Info</h2>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="far fa-clock"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Opening Hour</h3>
                                    <p>{OPEN_TIMINGS}</p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fa fa-phone-alt"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Call Us</h3>
                                    <p>
                                        <a style={{ 'color': 'darkcyan' }} href={"tel:" + MOBILE}>{MOBILE}</a>
                                    </p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="far fa-envelope"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Email Us</h3>
                                    <p>
                                        <a style={{ 'color': 'darkcyan' }} href={"mailto:" + EMAIL}>{EMAIL}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form name="contactForm" id="contactForm" onSubmit={handleSubmit}>
                                <div className="control-group pb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Your Name"
                                        name="name"
                                        value={inputs.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="control-group pb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Your Mobile Number"
                                        name="mobile"
                                        value={inputs.mobile}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="control-group pb-3">
                                    <textarea
                                        className="form-control"
                                        placeholder="Message"
                                        name="message"
                                        value={inputs.message}
                                        onChange={handleChange}
                                        required>
                                    </textarea>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-custom" >Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Locations />
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
