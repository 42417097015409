import { ADDRESS, CIN, EMAIL, MOBILE, REG_NO } from "../../Constants/common";
import { ROUTES } from "../../Constants/routes";
import useRedirect from "../../Hooks/useRedirect";

function AboutUs() {
  const { redirectTo } = useRedirect();

  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src="/assets/images/logo-2.png" alt="Image" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-header text-left">
              <p>About Us</p>
              <h2>Kamal Steel Genuine Spare Parts</h2>
            </div>
            <div className="about-content">
              <p>
                Kamalpushp Steel Industries Private Limited is a Private incorporated on 13 November 2018. It is classified as Non-govt company and is registered at Registrar of Companies, Delhi.
              </p>
              <p>
                Kamalpushp Steel Industries Private Limited’s Annual General Meeting (AGM) was last held on 18 December 2020 and as per records from Ministry of Corporate Affairs (MCA), its balance sheet was last filed on 31 March 2020.
              </p>
              {/* <p>
                Directors of Kamalpushp Steel Industries Private Limited are Jagdish Kumar Gupta and Prateek Kumar Gupta.
              </p> */}
              <p>CIN - {CIN}</p>
              <p>Registration Number - {REG_NO}</p>
              <p>Email Address - <a style={{ 'color': 'darkcyan' }} href={"mailto:" + EMAIL}>{EMAIL}</a></p>
              <p>Mobile Number - <a style={{ 'color': 'darkcyan' }} href={"tel:" + MOBILE}>{MOBILE}</a></p>
              <p>Address - {ADDRESS}</p>
              {/* <ul>
                <li><i className="far fa-check-circle"></i>Seats washing</li>
                <li><i className="far fa-check-circle"></i>Vacuum cleaning</li>
                <li><i className="far fa-check-circle"></i>Interior wet cleaning</li>
                <li><i className="far fa-check-circle"></i>Window wiping</li>
              </ul> */}
              <a className="btn btn-custom" onClick={() => redirectTo(ROUTES.CONTACT_US)} >Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
