function SectionDesign2({ items, title, desc }) {
    return (
        <div className="blog">
            <div className="container">
                <div className="section-header text-center">
                    <p>{title}</p>
                    <h2>{desc}</h2>
                </div>
                <div className="row">
                    {items && items.length && items.map((item, index) => {
                        return <div key={index} className="col-lg-3 col-6">
                            <div className="">
                                <div className="blog-img">
                                    <img src={item.imgUrl} className="service-item-img" />
                                </div>
                                <div className="blog-text">
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </div>
                                {/* <div className="blog-meta">
                                    <p><i className="fa fa-user"></i><a href="">Admin</a></p>
                                    <p><i className="fa fa-folder"></i><a href="">Web Design</a></p>
                                    <p><i className="fa fa-comments"></i><a href="">15 Comments</a></p>
                                </div> */}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
}

export default SectionDesign2;
