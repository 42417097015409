import { ROUTES } from "../../Constants/routes";
import useActivePageData from "../../Hooks/useActivePageData";
import useProductDetails from "../../Hooks/useProductDetails";
import useRedirect from "../../Hooks/useRedirect";

function ProductDetails({ productId }) {
  const { redirectTo } = useRedirect();
  const { currentActivePageData } = useActivePageData();
  const currentRouteData = currentActivePageData({});
  let productDetails = useProductDetails({ productId: productId || currentRouteData.params.productId });

  return (
    <div className="single">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="single-content">
              <h2>{productDetails.title}</h2>
              {productDetails.description && productDetails.description.map((d, index) => {
                return <p key={index} dangerouslySetInnerHTML={{ __html: d }}></p>
              })}

            </div>
            <div className="single-tags">
              {productDetails.tags.map((tag, index) => {
                return <a key={index}>{tag.name}</a>
              })}
            </div>
            <a className="btn btn-custom" onClick={() => redirectTo(ROUTES.CONTACT_US)} >Learn More</a>
          </div>

          <div className="col-lg-4">
            <div className="sidebar">
              <div className="sidebar-widget">
                <div className="single-bio">
                  <div className="single-bio-text">
                    <img src={productDetails.imgUrl} style={{ 'width': '100%' }} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
