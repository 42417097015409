import { useNavigate } from "react-router";

export default function useRedirect() {

    const navigate = useNavigate();

    const redirectTo = (redirectionUrl, queryParams = {}, relativeToUrl) => {
        if (queryParams) {
            redirectionUrl += '?' + Object.keys(queryParams).map(k => `${k}=${queryParams[k]}`).join('&');
        }
        navigate('/' + redirectionUrl, { replace: relativeToUrl || false });
        window.scrollTo({ top: 0, left: 0 });
    };

    return { redirectTo };
}