import { useRoutes } from "react-router";
import { ROUTES } from "./Constants/routes";
import AboutUs from "./Pages/AboutUs/aboutUs";
import ContactUs from "./Pages/ContactUs/contactUs";
import Customers from "./Pages/Customers/customers";
import Home from "./Pages/Home/home";
import Locations from "./Pages/Locations/locations";
import ProductDetails from "./Pages/ProductDetails/productDetails";

export const AllRoutes = () => {

    let routes = useRoutes([
        { path: ROUTES.HOME, element: <Home /> },
        { path: ROUTES.CONTACT_US, element: <ContactUs /> },
        { path: ROUTES.ABOUT_US, element: <AboutUs /> },
        { path: ROUTES.PRODUCT_ID, element: <ProductDetails /> },
        { path: ROUTES.CUSTOMERS, element: <Customers /> },
        { path: ROUTES.LOCATIONS, element: <Locations /> },
    ]);
    return routes;
}
