import { ADDRESS, EMAIL, MOBILE } from "../../Constants/common";
import { PRODUCTS } from "../../Constants/products";
import { ROUTES } from "../../Constants/routes";
import useRedirect from "../../Hooks/useRedirect";

function Footer() {
    const { redirectTo } = useRedirect();

    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-contact">
                            <h2>Get In Touch</h2>
                            <p><i className="fa fa-map-marker-alt"></i>{ADDRESS}</p>
                            <p>
                                <i className="fa fa-phone-alt"></i>
                                <a style={{ 'color': 'darkcyan' }} href={"tel:" + MOBILE}>{MOBILE}</a>
                            </p>
                            <p>
                                <i className="fa fa-envelope"></i>
                                <a style={{ 'color': 'darkcyan' }} href={"mailto:" + EMAIL}>{EMAIL}</a>
                            </p>
                            {/* <div className="footer-social">
                                <a className="btn" ><i className="fab fa-twitter"></i></a>
                                <a className="btn" ><i className="fab fa-facebook-f"></i></a>
                                <a className="btn" ><i className="fab fa-youtube"></i></a>
                                <a className="btn" ><i className="fab fa-instagram"></i></a>
                                <a className="btn" ><i className="fab fa-linkedin-in"></i></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Useful Links</h2>
                            <a onClick={() => redirectTo(ROUTES.CUSTOMERS)}>Customers</a>
                            <a onClick={() => redirectTo(ROUTES.LOCATIONS)}>Locations</a>
                            <a onClick={() => redirectTo(ROUTES.ABOUT_US)}>About Us</a>
                            <a onClick={() => redirectTo(ROUTES.CONTACT_US)}>Contact Us</a>
                            {/* <a >Our Service</a>
                            <a >Service Points</a>
                            <a >Pricing Plan</a> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Popular Links</h2>
                            {PRODUCTS.map((product, index) => {
                                return <a key={index} onClick={() => redirectTo(product.deeplink)}>{product.title}</a>
                            })}
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-offset-1 col-md-6">
                        <div className="footer-newsletter">
                            <h2>Contact Us</h2>
                            {/* <button onClick={() => redirectTo(ROUTES.CONTACT_US)} className="btn btn-custom">Contact Us</button> */}
                            {/* <form>
                                <input className="form-control" placeholder="Full Name" />
                                <input className="form-control" placeholder="Mobile Number" />
                                <input className="form-control" placeholder="Email (Optional)" />
                                <button onClick={() => redirectTo(ROUTES.CONTACT_US)} className="btn btn-custom">Contact Us</button>
                            </form> */}
                            <button onClick={() => redirectTo(ROUTES.CONTACT_US)} className="btn btn-custom">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <p>&copy; <a target="_blank" href="https://www.kamalsteel.in">www.kamalsteel.in</a>, All Right Reserved.</p>
                {/* Designed By <a href="https://htmlcodex.com">HTML Codex</a> */}
            </div>
        </div>
    );
}

export default Footer;
