import useRedirect from "../../Hooks/useRedirect";

function SectionDesign1({ items, title, desc }) {

    const { redirectTo } = useRedirect();

    return (
        <div className="service">
            <div className="container">
                <div className="section-header text-center">
                    <p>{title}</p>
                    <h2>{desc}</h2>
                </div>
                <div className="row">
                    {items && items.length && items.map((item, index) => {
                        return <div onClick={() => redirectTo(item.deeplink)} key={index} className="col-lg-3 col-md-6 col-6">
                            <div className="service-item">
                                <img src={item.imgUrl} className="service-item-img" />
                                <h3>{item.title}</h3>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
}

export default SectionDesign1;
