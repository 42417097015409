import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Carousal(props) {
    return (
        <div >
            <Carousel
                onChange={props?.onChange}
                onClick={props?.sendSectionEvent}
                autoPlay={props?.isAutoPlay === false ? false : true}
                infiniteLoop={props?.isInfiniteLoop === false ? false : true}
                showThumbs={false}
                showStatus={false}
                {...props}>
                {props?.items?.map((item, index) => {
                    return <div key={index} className="carousel-img-h"
                        onClick={() => {
                            props?.onClick && props?.onClick(item, index);
                        }}>
                        <img loading="lazy" src={item.imgUrl} />
                    </div>;
                })}
            </Carousel>
        </div>
    );
}

export default Carousal;
