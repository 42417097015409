import axios from 'axios';

export function serviceCall(options) {
    return new Promise((resolve, reject) => {
        axios(options.url, options)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
}