import { ROUTES } from "../../Constants/routes";
import useRedirect from "../../Hooks/useRedirect";

function SectionDesign3({ items, title, desc, showViewAllBtn }) {

    const { redirectTo } = useRedirect();

    return (
        <div className="testimonial">
            <div className="container">
                <div className="section-header text-center">
                    <p>{title}</p>
                    <h2>{desc}</h2>
                </div>
                <div className="testimonials-carousel row">
                    {items && items.length && items.map((item, index) => {
                        return <div className="testimonial-item col-md-4" key={index}>
                            {/* <img src="img/testimonial-1.jpg" alt="Image" /> */}
                            <div className="tag-name">
                                <h3>{item.initials}</h3>
                            </div>
                            <div className="testimonial-text">
                                <h3>{item.title}</h3>
                                {/* <h4>Profession</h4> */}
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    })}
                    {showViewAllBtn && <div className="text-center testimonial-view-all-btn">
                        <a className="btn btn-custom" onClick={() => redirectTo(ROUTES.CUSTOMERS)} >View All</a>
                    </div>}
                </div>
            </div>
        </div >
    );
}

export default SectionDesign3;
