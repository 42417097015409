export const PRODUCTS = [
    {
        id: 1,
        imgUrl: '/assets/images/brake-shoe.jpeg',
        title: 'Brake Shoe',
        desc: '',
        description: [
            "The brake shoe carries the brake lining, which is riveted or glued to the shoe.When the brake is applied, the shoe moves and presses the lining against the inside of the drum.The friction between lining and drum provides the braking effort.Energy is dissipated as heat.",
            "Modern cars have disc brakes all round, or discs at the front and drums at the rear.An advantage of discs is that they can dissipate heat more quickly than drums so there is less risk of overheating.",
            "The reason for retaining drums at the rear is that a drum is more effective than a disc as a parking brake.",
            "A brake shoe can be put on the track to stop a moving car. The wheel rolls up to the tongue and then the brake shoe glides with the car on the track until it stops.",
            "The brake shoe carries the brake block. The block was originally made of wood, then usage of cast iron (particularly grey iron) appear to be later replaced nowadays by High Friction composite material. When the brake is applied, the shoe moves and presses the block against the tread of the wheel.",
            "Engineered to eliminate noise, vibration, and harshness, Kamal Steel Brake Shoe provide the ultimate braking experience."
        ],
        tags: [],
        deeplink: 'product/1'
    },
    {
        id: 2,
        imgUrl: '/assets/images/air-filter.jpeg',
        title: 'Air Filter',
        desc: '',
        description: [
            'A particulate air filter is a device composed of fibrous, or porous materials which removes solid particulates such as dust, pollen, mold, and bacteria from the air. Filters containing an adsorbent or catalyst such as charcoal (carbon) may also remove odors and gaseous pollutants such as volatile organic compounds or ozone.',
            'Air filters are used in applications where air quality is important, notably in building ventilation systems and in engines.',
            "The cabin air filter is typically a pleated-paper filter that is placed in the outside-air intake for the vehicle's passenger compartment. Some of these filters are rectangular and similar in shape to the engine air filter. Others are uniquely shaped to fit the available space of particular vehicles' outside-air intakes.",
            "The combustion air filter prevents abrasive particulate matter from entering the engine's cylinders, where it would cause mechanical wear and oil contamination.",
            "Engineered to eliminate noise, vibration, and harshness, Kamal Steel Air Filter provide the ultimate filtering  experience."
        ],
        tags: [],
        deeplink: 'product/2'
    },
    {
        id: 3,
        imgUrl: '/assets/images/clucth-plate.jpeg',
        title: 'Clutch Plate',
        desc: '',
        description: [
            "A clutch is a mechanical device that engages and disengages power transmission, especially from a drive shaft to a driven shaft. In the simplest application, clutches connect and disconnect two rotating shafts (drive shafts or line shafts). In these devices, one shaft is typically attached to an engine or other power unit (the driving member), while the other shaft (the driven member) provides output power for work. Typically the motions involved are rotary, but linear clutches also exist.",
            "In a motor vehicle, the clutch acts as a mechanical linkage between the engine and transmission, and briefly disconnects, or separates the engine from the transmission system. This disconnects the drive wheels whenever the clutch pedal is depressed, allowing the driver to smoothly change gears.",
            "In a torque-controlled drill, for instance, one shaft is driven by a motor, and the other drives a drill chuck. The clutch connects the two shafts so they may be locked together and spin at the same speed (engaged), locked together but spinning at different speeds (slipping), or unlocked and spinning at different speeds (disengaged).",
            "Engineered to eliminate noise, vibration, and harshness, Kamal Steel Clutch Plate provide the ultimate filtering  experience."
        ],
        tags: [],
        deeplink: 'product/3'
    },
    {
        id: 4,
        imgUrl: '/assets/images/hub-rubber.jpeg',
        title: 'Hub Rubber',
        desc: '',
        description: [
            "Engineered to eliminate noise, vibration, and harshness, Kamal Steel Hub Rubber provide the ultimate filtering  experience."
        ],
        tags: [],
        deeplink: 'product/4'
    },
    {
        id: 5,
        imgUrl: '/assets/images/hero-chain-tansio.jpeg',
        title: 'Chain Tansio',
        desc: '',
        tags: [],
        deeplink: 'product/5'
    },
    {
        id: 6,
        imgUrl: '/assets/images/hero-chain-tanioner-roller.jpeg',
        title: 'Chain Tansioner',
        desc: '',
        tags: [],
        deeplink: 'product/6'
    },
    {
        id: 7,
        imgUrl: '/assets/images/oil-filter.jpeg',
        title: 'Oil Filter',
        desc: '',
        tags: [],
        deeplink: 'product/7'
    }
]