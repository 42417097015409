export const BANNERS = [
    {
        imgUrl: '/assets/images/banner2.jpg'
    },
    {
        imgUrl: '/assets/images/banner3.webp'
    },
    // {
    //     imgUrl: '/assets/images/banner5.png'
    // }
    // {
    //     imgUrl: '/assets/images/banner7.jpg'
    // },
    // {
    //     imgUrl: '/assets/images/banner8.jpg'
    // }
];