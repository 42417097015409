function Locations() {

    return (
        <div className="blog">
            <div className="container">
                <div className="section-header text-center">
                    <p>Locations</p>
                </div>
                <div className="col-md-12">
                    <div style={{ height: '400px', marginTop: '40px' }} dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.201641208806!2d77.26455170118363!3d28.722829781913937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfea6043a6f81%3A0x7a2de9495ddbd8ab!2sKarawal%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110094!5e0!3m2!1sen!2sin!4v1664886533177!5m2!1sen!2sin" width="100%" height="100%" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>' }} />
                </div>
            </div>
        </div>
    );
}

export default Locations;
