export const AWARDS = [
    {
        imgUrl: '/assets/images/awards1.jpeg',
        title: '',
    },
    {
        imgUrl: '/assets/images/awards2.jpeg',
        title: '',
        desc: ''
    },
    {
        imgUrl: '/assets/images/awards3.jpeg',
        title: '',
        desc: ''
    },
    {
        imgUrl: '/assets/images/awards4.jpeg',
        title: '',
        desc: ''
    }
]