export default {

    showLoader() {
        document.getElementById('loading').setAttribute("hidden", false);
    },

    hideLoader() {
        document.getElementById('loading').setAttribute("hidden", true);

    }
};