export const FACTS = [
    {
        className: 'fa fa-map-marker-alt',
        title: 'Service Points',
        start: 0,
        end: 2
    },
    {
        className: 'fa fa-user',
        title: 'Workers',
        start: 0,
        end: 100
    },
    {
        className: 'fa fa-users',
        title: 'Happy Clients',
        start: 0,
        end: 500
    },
    {
        className: 'fa fa-check',
        title: 'Projects Completed',
        start: 0,
        end: 5000
    },

]