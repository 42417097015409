import { CONTACT_API_SUBMIT_URL } from "../Config/prod.config";
import { serviceCall } from "./axios";

export const submitContactForm = (payload) => {
    const request = {
        url: CONTACT_API_SUBMIT_URL,
        method: 'post',
        data: payload
    };
    return serviceCall(request);
};