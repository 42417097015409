import { useRef, useState } from "react";
import useIsOnScreen from "../../Hooks/useIsOnScreen";
import CountUp from 'react-countup';


function Facts({ items }) {
    const ref = useRef();
    const isVisible = useIsOnScreen(ref);
    const [hasViewed, setHasViewed] = useState(false);
    if (isVisible && !hasViewed) {
        setHasViewed(true);
    }

    return (
        <div className="facts" ref={ref}>
            <div className="container">
                <div className="row">
                    {hasViewed && items && items.length &&
                        items.map((item, index) => {
                            return <div key={index} className="col-lg-3 col-md-6">
                                <div className="facts-item">
                                    <i className={item.className}></i>
                                    <div className="facts-text">
                                        <h3 data-toggle="counter-up">
                                            <CountUp delay={item.start} end={item.end} />
                                        </h3>
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

    );
}

export default Facts;
