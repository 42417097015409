import { PRODUCTS } from "../../Constants/products";
import { ROUTES } from "../../Constants/routes";
import useActivePageData from "../../Hooks/useActivePageData";
import useRedirect from "../../Hooks/useRedirect";

function Header() {

    const { redirectTo } = useRedirect();
    const { currentActivePageData } = useActivePageData();
    const currentRouteData = currentActivePageData({});

    return (
        <div>
            <div className="nav-bar">
                <div className="container">
                    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                        <a className="navbar-brand" onClick={() => redirectTo(ROUTES.HOME)} style={{ fontFamily: 'cursive' }}>Kamal Steel</a>
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <a onClick={() => redirectTo(ROUTES.HOME)} className={`nav-item nav-link ${currentRouteData.pathname == ROUTES.HOME ? 'active' : ''}`}>Home</a>
                                <div className="nav-item dropdown">
                                    <a data-toggle="dropdown" className={`dropdown-toggle nav-link ${currentRouteData.route == ROUTES.PRODUCT_ID ? 'active' : ''}`}>Products</a>
                                    <div className="dropdown-menu">
                                        {PRODUCTS.map((product, index) => {
                                            return <a key={index} onClick={() => redirectTo(product.deeplink)} style={{ 'textTransform': 'capitalize' }} className="dropdown-item">{product.title}</a>
                                        })}
                                    </div>
                                </div>
                                <a onClick={() => redirectTo(ROUTES.CUSTOMERS)} className={`nav-item nav-link ${currentRouteData.pathname == ROUTES.CUSTOMERS ? 'active' : ''}`}>Customers</a>
                                <a onClick={() => redirectTo(ROUTES.LOCATIONS)} className={`nav-item nav-link ${currentRouteData.pathname == ROUTES.LOCATIONS ? 'active' : ''}`}>Locations</a>
                                <a onClick={() => redirectTo(ROUTES.ABOUT_US)} className={`nav-item nav-link ${currentRouteData.pathname == ROUTES.ABOUT_US ? 'active' : ''}`}>About Us</a>
                            </div>
                            <div className="ml-auto">
                                <a onClick={() => redirectTo(ROUTES.CONTACT_US)} className={`btn btn-custom ${currentRouteData.pathname == ROUTES.CONTACT_US ? 'highlighted-btn' : ''}`}>Contact Us</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Header;
