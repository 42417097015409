export const CLIENTS = [
    {
        imgUrl: '',
        title: 'Amit Chauhan',
        initials: 'AC',
        desc: 'Very good service. Staff is very good.',
    },
    {
        imgUrl: '',
        title: 'Sagar Verma',
        initials: 'SV',
        desc: 'Genuine and Reliable products.'
    },
    {
        imgUrl: '',
        title: 'Tushar Singh',
        initials: 'TS',
        desc: 'Reasonable price and good staff behaviour.'
    },
    {
        imgUrl: '',
        title: 'Raman Jain',
        initials: 'RJ',
        desc: 'Very happy with the service.'
    },
    {
        imgUrl: '',
        title: 'Sunil',
        initials: 'S',
        desc: 'Very very good service.',
    },
    {
        imgUrl: '',
        title: 'Anshika',
        initials: 'SV',
        desc: 'Helping staff.'
    },
]