import SectionDesign3 from "../../Common/SectionDesign3/sectionDesign3";
import { CLIENTS } from "../../Constants/clients";

function Customers() {

    return (
        <div>
            <SectionDesign3 items={CLIENTS} title="Customers" desc="What our client says" />
        </div>
    );
}

export default Customers;
