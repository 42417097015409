import Carousal from "../../Common/Carousal/carousal";
import Facts from "../../Common/Facts/facts";
import SectionDesign1 from "../../Common/SectionDesign1/sectionDesign1";
import SectionDesign2 from "../../Common/SectionDesign2/sectionDesign2";
import SectionDesign3 from "../../Common/SectionDesign3/sectionDesign3";
import { AWARDS } from "../../Constants/awards";
import { BANNERS } from "../../Constants/banners";
import { CLIENTS } from "../../Constants/clients";
import { FACTS } from "../../Constants/facts";
import { PRODUCTS } from "../../Constants/products";

function Home() {

    return (
        <div>
            <Carousal items={BANNERS} />
            <SectionDesign1 items={PRODUCTS} title="Products" desc="Our fine quality Products" />
            <Facts items={FACTS} />
            <SectionDesign2 items={AWARDS} title="GENUINE PARTS" />
            <SectionDesign3 items={CLIENTS.slice(0, 3)} title="Testimonials" desc="What our client says" showViewAllBtn />
        </div>
    );
}

export default Home;
