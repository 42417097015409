import './App.css';
import TopHeader from './Common/TopHeader/topHeader';
import Header from './Common/Header/header';
import Footer from './Common/Footer/footer';
import { AllRoutes } from './Routes';
import * as loaderService from './Services/loader';

function App() {
  loaderService.default.hideLoader();

  return (
    <div className="App">
      <TopHeader />
      <Header />
      <AllRoutes />
      <Footer />
    </div>
  );
}

export default App;
