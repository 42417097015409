import { useLocation, useNavigate, useParams } from "react-router";
import { ROUTES } from "../Constants/routes";

export default function useActivePageData() {

    const location = useLocation();
    const params = useParams();


    const currentActivePageData = () => {
        let result = {};
        Object.keys(ROUTES).forEach((route) => {
            let ele = ROUTES[route];
            let pathname = location.pathname.substring(1);
            let newPath = [pathname];
            if (ele.indexOf('/:') > -1) {
                newPath = [];
                let splittedPath = ele.split('/:');
                splittedPath.forEach((pathEle) => {
                    if (params[pathEle]) {
                        newPath.push(params[pathEle]);
                    } else {
                        newPath.push(pathEle)
                    }
                });
            }
            newPath = newPath.join('/');
            if (newPath == pathname) {
                result = { pathname: pathname, params: params }
            }
        });
        return result;
    };

    return { currentActivePageData };
}