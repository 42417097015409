import { EMAIL, MOBILE, OPEN_TIMINGS } from "../../Constants/common";
import { ROUTES } from "../../Constants/routes";
import useRedirect from "../../Hooks/useRedirect";

function TopHeader() {
    const { redirectTo } = useRedirect();

    return (
        <div>
            <div className="top-bar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="logo">
                                <a onClick={() => redirectTo(ROUTES.HOME)}>
                                    <img src="/assets/images/logo-2.png" alt="Logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Opening Hours</h3>
                                            <p>{OPEN_TIMINGS}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Call Us</h3>
                                            <p><a style={{ 'color': 'darkcyan' }} href={"tel:" + MOBILE}>{MOBILE}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Email Us</h3>
                                            <p><a style={{ 'color': 'darkcyan' }} href={"mailto:" + EMAIL}>{EMAIL}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default TopHeader;
